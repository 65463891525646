import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import {
//   FaEnvelope,
//   FaFacebookF,
//   FaLinkedinIn,
//   FaTwitter,
// } from "react-icons/fa"
import { RiGatsbyLine } from "react-icons/ri"

export const Footer = () => {
  // const {
  //   site: {
  //     meta: { links },
  //   },
  // } = useStaticQuery(graphql`
  //   query FooterQuery {
  //     site {
  //       meta: siteMetadata {
  //         links {
  //           contact
  //           facebook
  //           linkedin
  //           twitter
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <footer className="bg-white">
      <div className="container pt-12 pb-12 flex flex-wrap text-center lg:flex-row-reverse lg:justify-between lg:items-center">
        <ul className="w-full lg:w-auto">
          {/* <FooterIconLink
            href={links.facebook}
            icon={FaFacebookF}
            label="Facebook"
          />
          <FooterIconLink
            href={links.twitter}
            icon={FaTwitter}
            label="Twitter"
          />
          <FooterIconLink
            href={links.linkedin}
            icon={FaLinkedinIn}
            label="LinkedIn"
          />
          <FooterIconLink
            href={links.contact}
            icon={FaEnvelope}
            label="E-mail"
          />{" "} */}
          <FooterIconLink
            href={"https://www.gatsbyjs.org/"}
            icon={RiGatsbyLine}
            label="Ontwikkeld met Gatsby"
          />
        </ul>
        <div className="w-full lg:w-auto pt-6 lg:pt-0 text-green-800 text-sm">
          &copy; 2023 Alle rechten voorbehouden.
        </div>
      </div>
    </footer>
  )
}

const FooterIconLink = ({ href, label, icon: Icon }) => {
  const linkParams = { href }

  if (href.startsWith("http")) {
    linkParams.target = "_blank"
    linkParams.rel = "noreferrer noopener"
  }

  return (
    <li className="inline-block px-2">
      <a
        {...linkParams}
        className="inline-flex h-8 w-8 border border-green-800 text-green-800 rounded-full items-center justify-center transition-colors duration-200 hover:text-white hover:bg-green-400 hover:border-green-400"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-3 h-3 fill-current" />
      </a>
    </li>
  )
}
